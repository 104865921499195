import { Col, Container, Row } from "react-bootstrap";
import { BsPersonBadge } from "react-icons/bs";
// import { BiTransfer } from "react-icons/bi";
import { FaCoins } from "react-icons/fa";
// import { IoIosPeople } from "react-icons/io";
import { HiCreditCard } from "react-icons/hi";

import "./Benefits.css";
import {
  MdAssistantDirection,
  // MdOutlineFastForward,
  // MdSignalWifiConnectedNoInternet0,
} from "react-icons/md";

const KeyFeatures = () => {
  return (
    <Container
      className="my-3 m-auto border-bottom"
      id="KeyFeatures"
      style={{ scrollMargin: "100px" }}>
      <Row>
        <Col md="8" className="text-center mt-4 m-auto mb-4" data-aos="fade-up">
          <h2 className="fethd my-2">
            Major B<span className="underline">en</span>efits
          </h2>
          <p className="feattxt mt-4">
            Several ways Empressa helps you take payments faster and more conveniently.
          </p>
        </Col>
      </Row>
      <Row className="mb-5">
        <Col md="6" className="d-flex my-2 rtl" data-aos="fade-right">
          <div className="text-end me-2 keytxt">
            <h6 className="fw-bold mb-2">Take Card Payments</h6>
            <p className="feattxt">
              Accept debit and credit card payments including contactless cards
            </p>
          </div>
          <HiCreditCard className="featIcons" />
        </Col>
        <Col md="6" className="d-flex my-2" data-aos="fade-left">
          <FaCoins className="featIcons" />
          <div className="ms-2 keytxt texxt">
            <h6 className="fw-bold mb-2">Own a POS with just ₦350</h6>
            <p className="feattxt">Own a POS terminal with a daily repayment of ₦350</p>
          </div>
        </Col>
        {/* <Col md="6" className="d-flex my-2 rtl" data-aos="fade-right">
          <div className="text-end keytxt me-2">
            <h6 className="fw-bold mb-2">Access Instant Business Loan</h6>
            <p className="feattxt">Receive up to N10m instant business loan</p>
          </div>
          <IoIosPeople className="featIcons" />
        </Col>

        <Col md="6" className="d-flex my-2" data-aos="fade-left">
          <MdOutlineFastForward className="featIcons" />
          <div className="ms-2 keytxt texxt">
            <h6 className="fw-bold mb-2">Real-time Payment Settlement</h6>
            <p className="feattxt">
              Receive instant settlement of all payments into your wallet
            </p>
          </div>
        </Col> */}
        {/* <Col md="6" className="d-flex my-2 rtl" data-aos="fade-right">
          <div className="text-end keytxt me-2 ">
            <h6 className="fw-bold mb-2">Take Offline Payments</h6>
            <p className="feattxt">
              Take electronic payments easily when there is no internet
            </p>
          </div>
          <MdSignalWifiConnectedNoInternet0 className="featIcons" />
        </Col>
        <Col md="6" className="d-flex my-2 " data-aos="fade-left">
          <BiTransfer className="featIcons" />
          <div className="ms-2 keytxt texxt">
            <h6 className="fw-bold mb-2">Take Bank Transfer Payments</h6>
            <p className="feattxt">
              Accept Bank Transfers with instant notification and settlement
            </p>
          </div>
        </Col> */}
        <Col md="6" className="d-flex my-2 rtl" data-aos="fade-right">
          <div className="text-end keytxt me-2">
            <h6 className="fw-bold mb-2">Assisted On-boarding</h6>
            <p className="feattxt">Visit an agent near you to sign up</p>
          </div>
          <MdAssistantDirection className="featIcons" />
        </Col>
        <Col md="6" className="d-flex my-2" data-aos="fade-left">
          <BsPersonBadge className="featIcons" />
          <div className="ms-2 keytxt texxt">
            <h6 className="fw-bold mb-2">Self On-boarding</h6>
            <p className="feattxt">
              Sign up online and start taking payments immediately
            </p>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default KeyFeatures;
