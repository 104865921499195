import Typical from "react-typical";
import { Button, Col, Container, Row } from "react-bootstrap";
// import Car1 from "./home-slider/screen-1.png";
// import Car2 from "./home-slider/screen-2.png";
// import Car3 from "./home-slider/screen-3.png";
// import Car4 from "./home-slider/screen-4.png";
// import Car5 from "./home-slider/screen-5.png";
// import Car6 from "./home-slider/screen-6.png";
import Nav from "react-bootstrap/Nav";

import AS1 from "./AsSeenLogo-1.png";
import AS2 from "./AsSeenLogo-2.png";
import "./Home.css";

const Home = () => {
  return (
    <Container fluid id="Home" className="Home">
      <Container fluid="md" className="mt-md-0 mt-3 py-5">
        <Row className="d-flex align-items-end">
          <Col lg="7" className="text-start mt-md-0 mt-5 text-white py-4">
            {/* <p className="check" data-aos="fade-up">
              CHECK OUT THE ONLY
            </p> */}
            <h2 className="checkhd" data-aos="fade-up" data-aos-delay="50">
              Payment{" "}
              <Typical
                steps={["App", 2500, "Device", 2500]}
                loop={Infinity}
                className="d-xl-inline d-block"
              />
            </h2>
            {/* <p className="checkp" data-aos="fade-up" data-aos-delay="150">
              You will ever need!
            </p> */}
            <div className="d-flex mt-4 mx-0">
              {/* <Nav.Link
                href="https://play.google.com/store/apps/details?id=com.app.empressa"
                className="mb-3  mb-sm-0 p-0"
                data-aos="fade-right"
                data-aos-delay="200">
                <Button className="me-1 me-sm-4 getBTN">GET STARTED</Button>
              </Nav.Link> */}
              <Nav.Link
                href="#KeyFeatures"
                className="m-0 p-0"
                data-aos="fade-left"
                data-aos-delay="250">
                <Button className="homeBTN keybtn">BENEFITS</Button>
              </Nav.Link>
            </div>

            <div
              className="my-md-2 mt-5 pt-md-4 d-flex flex-md-row flex-column"
              data-aos="fade-up"
              data-aos-delay="300">
              <p className="fw-light d-inline partxt">
                <span className="ontop pt-1">P</span>OWERED BY
              </p>
              <span className="mt-md-4 d-flex">
                <Nav.Link href="https://onepipe.com" target="blank" className="mt-3 p-0">
                  <img src={AS1} alt="" className="ASSEEN px-4" />
                </Nav.Link>
                <Nav.Link
                  href="https://www.supportmfb.com"
                  target="blank"
                  className="m-0 p-0">
                  <img src={AS2} alt="" className="ASSEEN" />
                </Nav.Link>
              </span>
            </div>
          </Col>
          {/* <Col lg="5" className="mt-5">
            <div
              id="carouselExampleControls"
              className="carousel slide carbg"
              data-bs-ride="carousel">
              <button
                className="carousel-control-prev me-2"
                type="button"
                data-bs-target="#carouselExampleControls"
                data-bs-slide="prev">
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Previous</span>
              </button>
              <div className="carousel-inner slide-cont">
                <div className="carousel-item carimg active">
                  <img src={Car1} className="sldie" alt="..." />
                </div>
                <div className="carousel-item carimg">
                  <img src={Car2} className="sldie" alt="..." />
                </div>
                <div className="carousel-item carimg">
                  <img src={Car3} className="sldie" alt="..." />
                </div>
                <div className="carousel-item carimg">
                  <img src={Car4} className="sldie" alt="..." />
                </div>
                <div className="carousel-item carimg">
                  <img src={Car5} className="sldie" alt="..." />
                </div>
                <div className="carousel-item carimg">
                  <img src={Car6} className="sldie" alt="..." />
                </div>
              </div>
              <button
                className="carousel-control-next position-absolute"
                type="button"
                data-bs-target="#carouselExampleControls"
                data-bs-slide="next">
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Next</span>
              </button>
            </div>
          </Col> */}
        </Row>
      </Container>
    </Container>
  );
};

export default Home;
